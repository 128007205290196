import {
  defaultScriptUrlPattern as DEFAULT_SCRIPT_URL_PATTERN,
  load,
} from "@fingerprintjs/fingerprintjs-pro";
import { actions } from "astro:actions";
import { FINGERPRINT_API_KEY } from "astro:env/client";

class FingerprintStore {
  async init(fingerprintId?: string) {
    if (fingerprintId) {
      return;
    }

    const fp = await load({
      apiKey: FINGERPRINT_API_KEY,
      scriptUrlPattern: [
        "/api/fp.js?version=<version>&apiKey=<apiKey>&loaderVersion=<loaderVersion>",
        DEFAULT_SCRIPT_URL_PATTERN,
      ],
      region: "ap",
      endpoint: "https://metrics.saturdayquiztime.com.au",
    });

    const { visitorId } = await fp.get();
    await actions.account.setFpId({ fingerprint_id: visitorId });
  }
}

export const fingerprintStore = new FingerprintStore();
